div > h5 {
  font-size: 20px;
  font-weight: lighter;
}

.container {
  max-width: 100%!important;
}

.service-grid {
	display: grid;
	grid-template-columns: 30% 70%;
	grid-template-rows: auto;
}

.list-group-item {
	padding: 0.5rem;
}

.fitting-height{
	height: auto;
}

.noselect {
	user-select: none;
}

.custom-spacing {
	margin-bottom: -25px;
}

.custom-card {
    padding: 0.25rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fffdff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.down {
	  border: solid black;
  	border-width: 0 3px 3px 0;
  	align-self: center;
  	padding: 5px;
  	margin-right:10px;
  	margin-bottom: 10px;
  	transform: rotate(45deg);
  	-webkit-transform: rotate(45deg);
}

@media only screen and (max-width: 882px) {
	.service-grid {
		grid-template-columns: 35% 65%;
	}
}

@media only screen and (max-width: 747px) {
  .service-grid {
    grid-template-columns: 40% 60%;
  }
}

@media only screen and (max-width: 637px) {
  .service-grid {
    grid-template-columns: 45% 55%;
  }

  .service-box > h4,
  .custom-card > h4 {
    font-size: 3.5vw!important;
  }

  .custom-desc > h5 {
    font-size: 3vw!important;
  }
}