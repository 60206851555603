#portfolio {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	z-index: 0;
}

#limpafiltro {
  margin-left: 10px;
}

.project-card {
	position: relative;
	width: 100%;
	margin: 0.5%;
}

.picture {
	display: block;
	width: 99%;
	height: auto;
}

.project-title {
	position: absolute;
	top: 30%;
	bottom: 0;
	left: 0;
	right: 0;
	height: 40%;
	width: 99%;
	opacity: 0;
	transition: .5s ease;
}

.project-card:hover {
	cursor: pointer;
}

.project-card:hover .picture {
	filter: brightness(25%);
	transition: .5s ease;
}

.project-card:hover .project-title {
	opacity: 1;
}

.text {
	color: white;
	font-size: 125%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
}

/* The Modal (background) */
#projectModal, #imageModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Disable scroll if needed*/
  outline: 0;
}

#projectModal {
  background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
}

#imageModal {
  background-color: rgb(52,58,64); /* Dark background */
}

/* Modal Content */
.modalContent {
  width: 90vw;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  margin: 10vh auto;
  padding: 4% 4%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.modalTitle {
	font-weight: bold;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 45px;
  font-weight: bold;
  z-index: 1;
  user-select: none;
  margin: -4vw -3vw 0 0;
}

.back {
  position: absolute;
  color: white;
  font-size: 20px;
  font-weight: bold;
  z-index: 10;
  user-select: none;
  margin-top: 2vh;
  margin-left: 1vw;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.back:hover,
.back:focus {
  color: #aaaaaa;
  text-decoration: none;
  cursor: pointer;	
}

#galeria {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

#galeria div {
	align-self: center;
	cursor: pointer;
}

#galeria img {
	display: block;
	width: 100%;
	height: auto;
	border: 1px white solid;
}

.foto {
	margin: 7vh auto;
	display: block;
	/*height: 90%;*/
	max-width: 90vw;
	max-height: 86vh;
	animation-name: zoom;
 	animation-duration: 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}
/*End of Modal*/

@media only screen and (max-width: 992px) {
	#portfolio, #galeria {
		grid-template-columns: 1fr 1fr;
	}
}

@media only screen and (max-width: 767px) {
	.picture {
		filter: brightness(100%);
	}

	.project-title {
		opacity: 1;
	}
}

@media only screen and (max-width: 576px) {
	#portfolio, #galeria {
		margin:10%;
		grid-template-columns: 1fr;
	}
}
