%search-input-expanded {
  width: 90%;
  border: none;
  border-bottom: 1px solid theme-color('secondary');
  transition: all ease 500ms;
  line-height: 150%;
}

.navbar {
  box-shadow: $box-shadow;
  background-color: #343a40;
  transition: all 250ms ease-in-out;
  padding: 0.5rem 10px;
  line-height: 0;
  flex-wrap: wrap;

  .form-search {
    flex-wrap: nowrap;
  }

  .navbar-nav {
    .nav-link {
      color: white;
      font-weight: 600;

      &:focus {
        color: white;
      }

      &:hover,
      &:focus:hover {
          color: white;
      }
    }
  }

  #logo-brand, svg#fg {
    height: $navbar-height;
    width: auto;
  }

  // .navbar-brand {
  //   padding: 0 !important;
  // }

  a svg {
    margin:0!important;
    padding: 0!important;
    max-height: 5rem;
    max-width: 5rem;
  }

  a img {
    margin:0!important;
    padding: 0!important;
    max-height: 5rem;
    max-width: 5rem;
  }

  .dropdown-item {
    font-weight: inherit;
  }

  form {

    &:hover input,
    button:hover+input {
      @extend %search-input-expanded;
    }

    input {

      &:focus,
      &:hover,
      &:active,
      &:valid {
        @extend %search-input-expanded;
      }

      width: 0;
      border: none;
      color: $gray-800;

      &::placeholder {
        color: $gray-400;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar {
    .dropdown-menu {
      left: -2.25rem;
      box-shadow: $box-shadow;
    }

    form input,
    form button,
    .nav-link {
      color: white;
      text-shadow: 2px 2px 1px black;

      &:focus {
        color: white;
      }

      &:hover,
      &:focus:hover {
        transform: scale(1.05);
        color: white;
        text-shadow: 1px 1px 1px rgba(255, 255, 255, .25);
      }
    }

    &:not(.affix) {
      border-color: rgba(255, 255, 255, 0.3);
      //text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
      background-color: transparent;
      box-shadow: none;

      .navbar-brand {
        color: rgba(255, 255, 255, 0.9);

        &:hover,
        &:focus {
          color: $white;
        }
      }

      .faded,
      .solid,
      #title {
        fill: $gray-200 !important;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
      }


    }
  }
}

@media (min-width: 767px) {
  .navbar {
    padding-top: 1.5rem !important;
  }
}