// Variables
@import 'variables';
@import 'functions';
// Bootstrap 
@import 'bootstrap';
// Other imports
@import 'keyframes';
@import 'navbar';
@import 'logo';
@import 'lazyload';
@import 'shadows';
@import 'services';
@import 'oportunidades';
@import 'portfolio';
@import 'contact';

@mixin rotate($deg: 90) {
  $sDeg: #{$deg}deg;
  transform: rotate($sDeg);
}

.rotate-45 {
  @include rotate(45);
}

$enable-shadows: true;

body {
  background-image: $gradient-formacao-global;
  text-shadow: .5px .5px .5px rgba(167, 167, 167, 0.25);
  font-weight: 300;

  .text-white,
  .text-light {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  }
}

article p {
  // text-align: justify;
  // text-justify: auto;
  hyphens: auto;
}

a {
  color: theme-color('secondary');
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.text-white a {
  color: $white;

  &:before,
  &:after {
    background-color: $white;
  }
}

$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);

/*article a:not(.no-hover) {
  text-decoration: none;
  background-image: linear-gradient(#343a40, #343a40);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: $easeOutBack background-size .2s;

  &:hover {
    background-size: 100% 2px;
    transition: $easeOutBack background-size .2s;
  }
}*/

p {

  &+h1,
  &+h2,
  &+h3,
  &+h4,
  &+h5,
  &+h6 {
    margin-top: 2rem;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 250;
  a {
    color: theme-color('deep-red')
  }
}

.vh-100 {
  height: 100vh;
  height: var(--vh);
}

header .row {
  padding-top: calc(#{$navbar-height} + 0.5rem);
/*  background-image: $gradient-deep-red;
  will-change: auto;
  background-image: $background-post;*/
  background-position: center center;
  background-size: cover;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1500ms ease(out-cubic) 50ms 1 normal forwards;
  will-change: auto;
  transform: translate3d(0,0,0);
}

svg {
  .none {
    fill: none;
  }
}

::selection {
  text-shadow: none;
  color: $white;
  background: $gray-600;
}

img {
  &::selection {
    color: $white;
    background: 0 0;
  }
}

.full-bleed {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}

#about {
  flex: 1;
}

#reviews {
  pointer-events: none;

  & > * {
    pointer-events: auto;
    transition: 300ms opacity, 300ms transform;
  }

  &:hover > *,
  &:focus-within > * {
    opacity: 0.5;
  }
    
  &:hover > :hover,
  &:focus-within > :focus {
    opacity: 1;
  }
}

// h1#pageTitle {
//   font-size: 24px;

//   @include media-breakpoint-up(md) {
//     font-size: 64px;
//   }
// }

.bg-hero {
  background-color: theme-color('deep-reed');
  background-image: $gradient-deep-red;
  will-change: transform;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(md) {
    background-image: $background-hero-portrait;
  }

  @include media-breakpoint-up(lg) {
    background-image: $background-hero-landscape;

    h1 {
      font-size: 5rem;
    }
  }
}

.list-articles {
  @include media-breakpoint-down(md) {
    h2 {
      font-size: 1.75rem;
    }
  }
}

.bg-shade-primary {
  height: inherit;
  background-color: $shade-deep-red;
}

.bg-deep-red {
  //background-color: theme-color('deep-red');
  background-image: $gradient-deep-red;
}

.bg-shade-gray {
  height: inherit;
  background-color: $shade-black;
}

.header-content-inner {
  hr {
    width: 5%;
    border-top: 3px solid theme-color('primary');
  }
}

.depoimentos {
  .row:nth-child(even) {
    flex-direction: row-reverse;
  }
}

#reviews .add-to-cart {
  cursor: pointer;
}

form.form .form-control {
  margin-bottom: 0.5rem;
}

.navbar-toggler-icon {
  background: transparent;
}

main section article,
main article section,
.depoimentos div.row,
.equipe div.row {
  opacity: 0;

  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      animation: fadeIn 150ms ease(out-back) #{$i * 100}ms 1 normal forwards;
    }
  }
}

.bg-formacao-global {
  background-image: $gradient-formacao-global;
}

.bg-photo {
  background-image: $gradient-photo;
}

.bg-services{
  background-image: $gradient-services;
}

.bg-explore {
  background-image: $gradient-explore;
}

.carousel-item,
.depoimentos-item,
.equipe-item {
  .rounded-amorph {
    width: 140px;
    align-self: center;
  }

  .rounded-amorph img {
    border-radius: inherit;
    margin-left: -5px;
  }

  &:nth-child(n) .rounded-amorph {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }

  &:nth-child(2n+1) .rounded-amorph {
    border-radius: 43% 57% 86% 14% / 65% 44% 56% 35%;
  }

  &:nth-child(3n) .rounded-amorph {
    border-radius: 76% 24% 79% 21% / 18% 9% 91% 82%;
  }
}

// .cor-fundacoes{
//   color: rgb(64,91,165);
// }

// .cor-governamentais{
//   color: rgb(166, 27, 74);
// }
// .cor-institucionais{
//   color: rgb(122, 81, 71);
// }

// .cor-organizacoes{
//   color: rgb(219, 68, 53);
// }
// .cor-iniciativa-privada{
//   color: rgb(78, 124, 189);
// }
// .cor-summer-winter-courses{
//   color: rgb(162, 191, 229);
// }
// .cor-professional-fellowship-programs{
//   color: rgb(239, 155, 149);
// }
// .cor-research-opportunities{
//   color: rgb(243, 150, 34);
// }
// .cor-oportunidades-gerais{
//   color: rgb(243, 179, 0);
// }

.custom-img { 
  width: 50vw;
  height: auto;
}

.equipe > div {
  margin-left: 3vw;
}