@mixin list-group-item-labels ($color:#000) {
  //background-color: lighten($color, $amount: 25);
  //color: darken($color, $amount: 25);
  background-color: lighten($color, $amount: 20);
}

.item-bolsas-fundacoes {
  @include list-group-item-labels(#5781f3);
}

.item-bolsas-institucionais {
  @include list-group-item-labels(#40c98b);
}

.item-bolsas-governamentais {
  @include list-group-item-labels(#dd5b4f);
}

.item-bolsas-privadas {
  @include list-group-item-labels(#f4eb37);
}

.item-bolsas-org-int {
  @include list-group-item-labels(#9a9da1);
}

.item-bolsas-summer-coursers {
  @include list-group-item-labels(#29b1d3);
}

.item-bolsas-gerais {
  @include list-group-item-labels(#9658a8);
}