.header-box-shadow {
  box-shadow: inset 0px -10px 10px -5px $shade-black, inset 0px 100px 100px -75px $shade-black;
}

.small-inset-box-shadow-y {
  box-shadow: inset 0px -10px 10px -5px $shade-black, inset 0px 10px 10px -5px $shade-black;
}

.small-inset-box-shadow-b {
  box-shadow: inset 0px -10px 10px -5px $shade-black;
}

.small-inset-box-shadow-t {
  box-shadow: inset 0px 10px 10px -5px $shade-black;
}

.embed-responsive {
  box-shadow: $box-shadow;
}

#reviews,
article footer aside {
  .card {
    box-shadow: $box-shadow-sm;
  }
}

#reviews,
section#explore,
section#services,
section#scholarships {
  .card {
    &:hover {
      box-shadow: $box-shadow;
    }
  }
}

ul.pagination li,
form.form .btn {
  box-shadow: $box-shadow-sm;
  &:hover {
    box-shadow: $box-shadow;
  }
}