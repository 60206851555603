// primary b71c1c
$theme-colors: ( 'primary': #7f1517, 'secondary': #ac313f, 'danger': #d84315, 'success': #689f38, 'warning': #f9a825, 'deep-red': #7f1517, 'pink':#fcd4d2);
$white: #fffdff;
$navbar-height: 2.75rem;
//$shade-deep-red: rgba($color:#7f1517, $alpha: .75);
$shade-deep-red: linear-gradient(to top, #7f1517b4 0%, #7e202bce 99%, #d84215b6 100%);
$shade-black: rgba(0, 0, 0, 0.5);
$background-post: url(../img/bg/post.jpg);
$background-blog: url(../img/bg/blog.jpg);
$background-experiencia-compartilhada: url(../img/bg/experiencia-compartilhada.jpg);
$background-faq: url(../img/bg/faq.jpg);
$background-servicos: url(../img/bg/servicos.jpg);
$background-hero-landscape: url(../img/bg/hero-landscape.jpg);
$background-hero-portrait: url(../img/bg/hero-portrait.jpg);

$gradient-photo: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
$gradient-formacao-global: linear-gradient(to bottom, #fffdff 0%, #faf8fa 100%);
$gradient-deep-red: linear-gradient(to top, #7f1517 0%, #7e202b 99%, #d84315 100%);
$gradient-explore: linear-gradient(to top, #f3e7e9 0%, #f3e7e9 100%);
$gradient-services: linear-gradient(to top, #f8e1f3 0%, #ffe8fa 1%, #e6dee9 100%);