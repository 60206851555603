.contact-container {
	display: flex;
	justify-content: center;
}

#container {
	margin-right: 1.5%;
}

.custom-col-sm-6 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%; 
	max-width: 100%;
}

.custom-contact-margin {
	margin-left: 10rem!important;
	margin-right: 10rem!important;
}

.mapouter {
	position:relative;
	text-align:right;
	width: 55%;
	margin-left: 1.5%;
}
.gmap_canvas {
	overflow:hidden;
	background:none!important;
}

iframe {
	padding: 3%;
	width: 100%;
}

@media only screen and (max-width: 1000px) {
	.mapouter {
		width: 80%;
	}
}

@media only screen and (max-width: 768px) {
	.contact-container {
		flex-direction: column;
	}

	#container {
		margin-right: 0;
		margin-bottom:3%;
	}

	.custom-contact-margin {
		margin-left: 1rem!important;
		margin-right: 1rem!important;
	}

	.mapouter {
		width: 100%;
		margin-left: 0;
		margin-top: 3%;
	}
}